export default function sortButtons(updatePageCallback) {
    const sortButtonsList = document.querySelectorAll('.sort-button')
    const sortStatesCount = 3
    if (sortButtonsList.length < 1) return
    const url = new URL(location)
    const currentSort = document.querySelector('[data-sort]')

    sortButtonsList.forEach((button) => {
        const buttonDataValue = button.dataset.value

        if (!buttonDataValue) {
            button.disabled = true
            return
        }

        const upArrow = button.querySelector('.arrow-top'),
            downArrow = button.querySelector('.arrow-bottom')
        let counter = 0
        showActiveSort(currentSort.dataset?.sort)

        button.addEventListener('click', () => {
            let buttonValue = 'default'
            if (!button.classList.contains('selected')) {
                removeButtonsListSelectedClasses(sortButtonsList)
                counter = 0
            }

            counter = (counter + 1) % sortStatesCount

            if (counter === 0) buttonValue = `-${buttonDataValue}`
            else if (counter === 1) buttonValue = buttonDataValue

            url.searchParams.set('sort', buttonValue)
            window.history.replaceState(null, null, decodeURIComponent(url))

            updatePageCallback()
            showActiveSort(url.searchParams.get('sort'))
        })

        function showActiveSort(currentSort) {
            if (!currentSort || !currentSort.includes(buttonDataValue)) {
                removeButtonSelectedClasses(button)
                counter = 0
                return
            }

            button.classList.add('selected')
            if (currentSort.startsWith('-')) {
                downArrow.classList.add('active')
                upArrow.classList.remove('active')
                counter = 1
            } else {
                upArrow.classList.add('active')
                downArrow.classList.remove('active')
                counter = 2
            }
        }
    })
}

function removeButtonsListSelectedClasses(buttonsList) {
    buttonsList?.forEach(removeButtonSelectedClasses)
}

function removeButtonSelectedClasses(button) {
    if (!button) return
    button.classList.remove('selected')
    button.querySelector('.arrow-top').classList.remove('active')
    button.querySelector('.arrow-bottom').classList.remove('active')
}
