import './radioButtonsSort.sass';

export default function sortRadioButtons(updatePageCallback) {
    const sortingWrappers = document.querySelectorAll('.radio-buttons-sort-wrapper');
    if (sortingWrappers.length < 1) return;

    const currentSort = document.querySelector('[data-sort]');
    const url = new URL(location);

    sortingWrappers.forEach(sortWrapper => {
        const sortingItems = sortWrapper.querySelectorAll('input[type="radio"]');
        const closeSortingElements = sortWrapper.querySelectorAll('.close-sort-list');
        const openSorting = sortWrapper.parentNode.querySelector('.open-sorting');

        sortingItems.forEach(radio => {
            if (radio.value === currentSort?.dataset.sort) {
                radio.checked = true;
                showCurrentSortLabel(radio);
            }

            radio.addEventListener('change', () => handleRadioChange(radio));
        });

        openSorting?.addEventListener('click', () => {
            sortWrapper.classList.add('js-active');
            document.body.classList.add('overflow-hidden');
        });

        closeSortingElements.forEach(item => item.addEventListener('click', closeSorting));
    });

    function updateRadioButtons(value) {
        sortingWrappers.forEach(sortWrapper => {
            const radioButtons = sortWrapper.querySelectorAll('input[type="radio"]');
            radioButtons.forEach(radio => {
                if (radio.value === value) {
                    radio.checked = true;
                    showCurrentSortLabel(radio);
                }
            });
        });
    }

    function showCurrentSortLabel(radio) {
        const sortRadioButtonLabel = radio.closest('.radio-buttons-sort-wrapper')
            .parentNode
            .querySelector('.open-sorting .current-sort-label');
        if (sortRadioButtonLabel) {
            sortRadioButtonLabel.textContent = radio.nextElementSibling.textContent;
        }
    }

    function closeSorting() {
        sortingWrappers.forEach(sortWrapper => sortWrapper.classList.remove('js-active'));
        document.body.classList.remove('overflow-hidden');
    }

    function handleRadioChange(radio) {
        !radio.value
            ? url.searchParams.delete('sort')
            : url.searchParams.set('sort', radio.value)

        window.history.replaceState(null, null, decodeURIComponent(url))

        updatePageCallback();
        updateRadioButtons(radio.value);
        closeSorting();
    }
}

document.addEventListener('DOMContentLoaded', () => {
    const sortRadioButtons = document.querySelectorAll('.radio-button-sort input[type="radio"]')
    if (sortRadioButtons.length < 1) return;

    sortRadioButtons.forEach(radio => {
        if (radio.value === 'rating' || radio.value === 'stock') {
            radio.parentNode.classList.add('hidden')
        }
    })
})