import '@js/app'
import '@components/Product/product'
import '@css-components/Product/product_new.scss'
import { listenPartials } from '@other/helper'
import { setCookie } from '@other/cookies'
import createAdSlider from '@other/createAdSlider'
import '@css-components/checkbox/checkbox-group'
import breakpoints from '@/js/other/breakpoints'
import sortButtons from '@/js/components/sortButtons'
import { catalogListDto } from '@/js/repositories/catalog/dto'
import { ProductRepository } from '@/js/repositories'
import '@css-pages/_catalog.scss'
import { CatalogService } from '@/js/services/catalog'
import { Tooltip } from '@/js/components/tooltip/index'
import sortRadioButtons from '@/js/components/RadioButtonsSort'
// TODO: Разобрать filter.js на отдельные компоненты и использовать тут компонент Pagination

$('body').on('click', '[data-action]', (event) => {
    const { action, type } = event.currentTarget.dataset
    if (action === 'openSidebar') {
        openSidebar(type)
    }

    if (action === 'closeSidebar') {
        closeSidebar()
    }
})

function openSidebar(type) {
    const sidebar = document.querySelector(`.group.${type}`)
    sidebar.classList.add('js-active')
    document.body.classList.add('overflow-hidden')
}

function closeSidebar() {
    const sidebar = document.querySelector(
        `.group.filters.js-active, .group.brand-info.js-active`
    )
    sidebar.classList.remove('js-active')
    document.body.classList.remove('overflow-hidden')
}

let filter = null
let manufacturerEmitter = null

if (document.querySelector('.Page-catalog')) {
    const $body = $('body')
    {
        if (document.querySelector('.filters div:not(.Loadable--active')) {
            initFilters()
        } else {
            listenPartials('catalog_filters', () => {
                initFilters()
            })
        }

        async function initFilters() {
            const Filters = await import('@parts/filters')
            filter = new Filters.default()
            filter?.emitter.on('remove:option', (optionName) => {
                if (optionName === 'find') {
                    if (manufacturerEmitter) {
                        manufacturerEmitter?.emit('clearInput')
                    } else {
                        document.querySelector('h1').innerText = 'Каталог'
                    }
                }
            })
            $('.filters.Loadable--active').removeClass('Loadable--active')
        }
    }

    sortRadioButtons(doubleRerender)
    sortButtons(doubleRerender)

    function watchPageSearchParams() {
        const originalPushState = history.pushState
        const originalReplaceState = history.replaceState

        history.pushState = function () {
            originalPushState.apply(history, arguments)
            window.dispatchEvent(new Event('pushstate'))
        }

        history.replaceState = function () {
            originalReplaceState.apply(history, arguments)
            window.dispatchEvent(new Event('replacestate'))
        }

        function trackPageChange(callback) {
            let previousPage = new URLSearchParams(window.location.search).get(
                'page'
            )

            function checkPageChange() {
                const currentPage = new URLSearchParams(
                    window.location.search
                ).get('page')

                if (currentPage !== previousPage) {
                    previousPage = currentPage
                    callback(currentPage)
                }
            }
            window.addEventListener('popstate', checkPageChange)
            window.addEventListener('pushstate', checkPageChange)
            window.addEventListener('replacestate', checkPageChange)

            checkPageChange()
        }
        trackPageChange((newPage) => sortButtons(doubleRerender))
    }
    watchPageSearchParams()

    // по одному запросу не происходит перерисовка страницы 
    function doubleRerender() {
        updateContent()
        updateContent()
    }

    function getSortParamsFromUrl() {
        const url = new URL(location)
        return url.searchParams.get('sort')
    }

    function updateContent() {
        const hiddenManuf = document.querySelector("[name='hiddenManuf']")?.value;
        const dto = catalogListDto({
            ...filter.options,
            sort: getSortParamsFromUrl(),
            hiddenManuf,
        });

        document.querySelector('.Page-catalog').classList.add('Loadable--active');
        ProductRepository.getCatalog(dto)
            .then(({ data, status }) => {
                if (status === 'error') return;
                replaceProducts(data);
            })
            .catch(error => {
                console.error("Ошибка при получении каталога:", error);
            })
            .finally(() => {
                document.querySelector('.Page-catalog').classList.remove('Loadable--active');
            });
    }

    function replaceProducts(content) {
        const parser = new DOMParser();
        const doc = parser.parseFromString(content, "text/html");
        const products = doc.querySelector(".products");
        const noResults = doc.querySelector(".no-result");
        const currentProducts = document.querySelector(".products");


        if (!currentProducts) return

        noResults
            ? currentProducts.innerHTML = noResults.innerHTML
            : currentProducts.replaceWith(products)
    }
    // Логика для поиска в Sort
    {
        $body.on('submit', '.sort-search', (e) => {
            e.preventDefault()

            const input = e.target.querySelector('input')

            if (input.value.trim()) {
                $(document).trigger('sort_search_submitted')
            } else {
                if (input._tippy) {
                    input._tippy.show()
                }
            }
        })

        $body.on('click', '.sort-search .cancel_round', (e) => {
            const elements = getElements(e.target)
            elements.input.value = ''
            elements.search.classList.remove('is-active')
            elements.icons.classList.remove('active')
            $(document).trigger('sort_search_cleared')
        })

        $body.on('input', '.sort-search input', (e) => {
            const elements = getElements(e.target)

            const value = e.target.value.trim()

            if (value) {
                elements.search.classList.add('is-active')
                elements.icons.classList.add('active')

                if (e.target._tippy) {
                    e.target._tippy.hide()
                }
            } else {
                elements.search.classList.remove('active')
                elements.icons.classList.remove('active')
            }
        })

        function getElements(target) {
            const search = target.closest('.sort-search')

            return {
                search,
                input: search.querySelector('input'),
                icons: search.querySelector('.cancel_round'),
            }
        }
    }

    const getBannerOffer = () => {
        const banner = document.querySelector('.catalog__banner')
        return banner?.dataset?.offer || ''
    }
    /**
     *
     * @param {HTMLElement} el
     */
    const createCookieOnDestroy = (el) => {
        if (!el) return

        const date = new Date(Date.now() + 86400e3).toUTCString(),
            cookieName = el?.getAttribute('data-cookie-name')
        setCookie(cookieName, 1, { expires: date })
    }

    const initSlider = () => {
        const slider = createAdSlider('.catalog__banner')
        slider?.on('destroy', () => {
            createCookieOnDestroy(slider.root)
        })
    }
    /**
     *
     * @param {HTMLElement|null} root
     * @param {boolean} shouldRemove
     */
    const removeSliderCloseBtn = (root, shouldRemove = false) => {
        if (root && shouldRemove) {
            root.querySelector('[data-action="closeBanner"]')?.remove()
        }
    }

    // Рекламные баннеры
    {
        const sliderBannerWrapper = document.querySelector('.catalog__banner')
        if (!breakpoints.currentGreaterThan('middle') && sliderBannerWrapper) {
            const dataOffer = getBannerOffer()
            const result = CatalogService.getBanner(dataOffer)

            result.then(({ status, data }) => {
                if (status === 'error' || !data)
                    return sliderBannerWrapper?.remove()

                sliderBannerWrapper.innerHTML = data
                initSlider(data)
                removeSliderCloseBtn(sliderBannerWrapper, !!dataOffer)
            })

            const bannerDeficitClose = document.querySelector(
                "[data-action='closeDeficit']"
            )
            if (bannerDeficitClose) {
                bannerDeficitClose.onclick = () => {
                    createCookieOnDestroy(bannerDeficitClose)
                    bannerDeficitClose.parentNode.remove()
                }
            }
        }

        Tooltip(
            "[data-action=closeBanner], [data-action='closeDeficit']",
            ({ setContent }) => setContent('Скрыть на 24 часа')
        )
    }
}

if (document.querySelector('.Page-catalog-manuf')) {
    import(
        /* webpackChunkName: 'cabinet.manufacturer' */ './catalog.manufacturer'
    ).then((data) => {
        manufacturerEmitter = data.default

        manufacturerEmitter.on('search', (findValue) => {
            filter?.emitter.emit('update:content', findValue)
        })
    })
}